<template>
  <div class="mall">
    <tab-nav isActive="mall"></tab-nav>
    <div class="gold-box flex align-center">
      <div
        class="gold-img flex align-center"
        @click="token==''?changeOpenLogin():''"
      >
        <img
          :src="token==''?login_img:gold_img"
          alt=""
        >
      </div>
      <div
        class="gold-text flex"
        v-if="token==''"
        @click="token==''?changeOpenLogin():''"
      >
        <p class="no-user"> </p>
        <i
          @click="openRuleFade"
          class="icon doubt-icon"
        ></i>
      </div>

      <div
        class="gold-text"
        v-else
      >
        <p class="gold-num flex">當前可用金銖:{{balance}} <i
            @click="openRuleFade"
            class="icon doubt-icon"
          ></i></p>
        <p class="gold-tip">每儲值滿500元寳，可獲得1金銖</p>
      </div>
      <a
        href="https://www.sanguosha.com.tw/login"
        class="icon rechage-btn"
      ></a>
    </div>
    <div class="shop-box">
      <a
        href="https://www.facebook.com/mysanguosha"
        class="icon kefu-btn"
      ></a>
      <p class="gold-title"></p>
      <p class="title"></p>
      <ul class="shop-list shop1 flex space-btween">
        <li
          v-for="(item,index) in shop.generals"
          :key="index"
        >
          <div class="hero">
            <div
              class="hero-header"
              :class="'hero-header'+index"
            >
              <img
                :src="item.icon"
                alt=""
              >
            </div>
            <p class="hero-name">{{item.name}}</p>
          </div>
          <p class="gold-count">{{item.price}}金銖</p>
          <a
            href="javascript:;"
            @click="changeExchange(item.item_id)"
            class="icon exchange-btn"
          ></a>
          <!-- <p class="exchange-text" v-else>今日兌換次數已達</p> -->
        </li>
      </ul>
      <p class="title title2"></p>
      <ul class="shop-list shop2 flex space-btween">
        <li
          v-for="(item,index) in shop.dresses"
          :key="index"
        >
          <div class="hero">
            <div
              class="hero-header"
              :class="'hero-header'+index"
            >
              <img
                :src="item.icon"
                alt=""
              >
            </div>
            <p class="hero-name">{{item.name}}</p>
          </div>
          <p class="gold-count">{{item.price}}金銖</p>
          <a
            href="javascript:;"
            @click="changeExchange(item.item_id)"
            class="icon exchange-btn"
          ></a>
          <!-- <p class="exchange-text" v-else>今日兌換次數已達</p> -->
        </li>
      </ul>
      <p class="title title3"></p>
      <ul class="shop-list flex space-btween">
        <li
          v-for="(item,index) in shop.props"
          :key="index"
        >
          <div class="hero">
            <div class="hero-header">
              <img
                :src="item.icon"
                alt=""
              >
            </div>
            <p class="hero-name">{{item.name}}</p>
          </div>
          <p class="gold-count">{{item.price}}金銖</p>
          <a
            href="javascript:;"
            @click="changeExchange(item.item_id)"
            class="icon exchange-btn"
          ></a>
          <!-- <a href="javascript:;" @click="changeExchange(item.item_id)" v-if="item.day_remain>0" class="icon exchange-btn"></a> -->
          <!-- <p class="exchange-text" v-else>今日兌換次數已達</p> -->
        </li>
      </ul>
    </div>
    <div
      class="fade"
      v-if="isLoginFade||ruleModel||isSureAlert"
      @click="changeCloseModel"
    ></div>
    <div
      class="rule-fade"
      v-if="ruleModel"
    >
      <a
        href="javascript:;"
        class="icon close-btn"
        @click="changeCloseModel"
      ></a>
      <div class="rule-box">
        <p>1、金銖僅供當前賬號使用，兌換道具後不可退換；</p>
        <p>2、金銖商城道具需綁定遊戲賬號，才能參與兌換；</p>
        <p>3、金銖商城上線後，儲值可獲得金銖，儲值渠道不限；</p>
        <p>4、金銖有效期為1年，到期未使用金銖將失效清零，請您在金銖到期前盡快使用；</p>
        <p>5、金銖計劃作為玩家回饋計劃的一部分，將隨著遊戲發展和玩家需求的變化做不定期的調整和優化，臨時性調整以公告為準，敬請關注。</p>
      </div>

    </div>

    <div
      class="sure-alert"
      v-if="isSureAlert"
    >
      <div class="btn-box flex space-btween">
        <a
          href="javascript:;"
          class="icon sure-btn"
          @click="changeSureExchange"
        ></a>
        <a
          href="javascript:;"
          class="icon cancel-btn"
          @click="changeCloseModel"
        ></a>
      </div>
    </div>
    <login
      :isLoginFade="isLoginFade"
      v-on:changeCloseFade="changeCloseModel"
    ></login>

  </div>
</template>
<script>
import tabNav from '../../components/tabNav/tabNav.vue'
import login from '../../components/login/login.vue'
import icon1 from '../../assets/login_img.png'
import icon2 from '../../assets/gold_img.png'
export default {
  name: 'mall',
  components: {
    tabNav,
    login
  },
  props: {
    msg: String
  },

  data: function () {
    return {
      shop: {
        dresses: [], //造型
        generals: [], //武将
        props: [] //道具
      },
      ruleModel: false,
      isLoginFade: false,
      token: localStorage.getItem('token') || '',
      shopId: '',
      isSureAlert: false,
      balance: 0,
      userInfor: {
        avatar: '',
        nickname: '',
        id: ''
      },
      gold_img: icon2,
      login_img: icon1
    }
  },

  created: function () {
    let _this = this
    if (_this.token !== '') {
      _this.getUserInfor()
      _this.getBalance()
    }
    _this.getShopItem()
    _this.getBalance()
  },
  methods: {
    toast: function (text) {
      let _this = this
      _this.errMsg = text
      _this.loading = true
      setTimeout(function () {
        _this.loading = false
      }, 2000)
    },
    changeEyes: function () {
      this.isHidePas = !this.isHidePas
    },
    changeOpenLogin: function () {
      this.isLoginFade = true
    },
    openRuleFade: function () {
      this.ruleModel = true
    },
    changeOpenRuleModel() {
      this.ruleModel = true
    },
    changeCloseModel() {
      this.ruleModel = false
      this.isLoginFade = false
      this.isSureAlert = false

      this.token = localStorage.getItem('token')
      this.getUserInfor()
      this.getBalance()
      this.getShopItem()
    },
    getUserInfor: function () {
      let _this = this
      this.$http.get('/auth/userinfo', {}, (res) => {
        if (res.code == 0) {
          _this.userInfor = res.data
        } else {
          if (res.code == 401) {
            _this.balance = 0
            _this.token = ''
            localStorage.clear()
          } else {
            this.$toast.center(res.msg)
          }
        }
      })
    },
    getShopItem: function () {
      let _this = this
      _this.$loading()
      this.$http.get('/cdmall/item', {}, (res) => {
        _this.shop = res.data
        _this.$loading.close()
      })
    },
    getBalance: function () {
      let _this = this
      this.$http.get('/cdmall/balance', {}, (res) => {
        if (res.code == 0) {
          _this.balance = res.data.balance
          _this.$loading.close
        } else {
          if (res.code == 401) {
            _this.balance = 0
            _this.token = ''
            localStorage.clear()
          } else {
            this.$toast.center(res.msg)
          }
        }
      })
    },
    changeSureExchange() {
      let itemdata = {
        item_id: this.shopId
      }
      let _this = this
      this.$http.post('/cdmall/purchase', itemdata, (res) => {
        if (res.code == 0) {
          _this.getShopItem()
          _this.getBalance()
          this.isSureAlert = false
          this.$toast.center('今天您還可以兌換' + res.data.day_remain + '次')
        } else {
          this.$toast.center(res.msg)
        }
      })
    },
    changeExchange: function (id) {
      let _this = this
      this.shopId = id
      if (_this.token == '') {
        _this.isLoginFade = true
      } else {
        this.isSureAlert = true
      }
    }
  }
}
</script>
<style lang="scss">
@import '/mall';
</style>