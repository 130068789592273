<template>
  <div class="heroDetail">
    <Header title="武將攻略详情"></Header>
    <div class="content">
      <ul class="tab-btn flex align-center">
        <li
          :class="['flex align-center',tab==0?'active':'']"
          @click="changeCurrntTab(0)"
        ><i class="icon font1"></i></li>
        <li class="line"></li>
        <li
          :class="['flex align-center',tab==1?'active':'']"
          @click="changeCurrntTab(1)"
        ><i class="icon font2"></i></li>
      </ul>
      <div
        v-if="tab==0"
        class="strategy-heroDetail"
      >
        <p class="hero-name">{{detail.name}}</p>
        <div class="hero-into flex">
          <p>{{detail.intro}}</p>
          <div class="heroImg"> <img
              :src="detail.img"
              alt=""
            ></div>
        </div>
        <div
          class="skill-into"
          v-html="detail.guide"
        >
          <!-- <p class="skill-title">技能介紹：</p>
                    <p class="into-text">標準版<br>
                    奸雄——當你受到傷害後，你可以獲得造成此傷害的牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    界限突破<br>>
                    奸雄——當你受到傷害後，你可以獲得對你造成傷害的牌並摸一張牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    </p>
                   <p class="skill-title">技能解析：</p>
                    <p class="into-text">標準版<br>
                    奸雄——當你受到傷害後，你可以獲得造成此傷害的牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    界限突破<br>>
                    奸雄——當你受到傷害後，你可以獲得對你造成傷害的牌並摸一張牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    </p>
                     <p class="skill-title">FAQ</p>
                     <div class="faq">
                         <p class="into-text">
                             [Q]【奸雄】是否在滿足條件時必須發動？<br>
                             [A]【奸雄】可以選擇性發動。
                         </p>
                        <p class="into-text">[Q]當錦囊牌對曹操造成傷害時，曹操獲得哪張牌？
                            [A]只獲得相應的錦囊，例如曹操判定【閃電】受到傷害，可以將【閃電】收入手牌。<br>
                            [Q]角色A對角色B使用【借刀殺人】，要求B對曹操使用【殺】，若B使用了【殺】對曹操造成傷害，曹操獲得哪張牌？
                        </p>

                     </div> -->

        </div>
      </div>
      <div
        v-if="tab==1"
        class="modelling"
      >
        <div
          class="hero-swiper"
          v-if="detail.stories[0].img!==''"
        >
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item,index) in detail.stories"
                :key="index"
              >
                <img
                  :src="item.img"
                  alt=""
                >
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
          <p class="hero-name">{{detail.stories[activeIndex].name}}</p>
        </div>
        <div
          class="into-box"
          v-html="detail.stories[activeIndex].content"
        >
          <!-- <p class="person-name">·魏武帝-曹操·</p>
                   <div class="skill-into">
                    <p class="skill-title">技能介紹：</p>
                    <p class="into-text">標準版<br>
                    奸雄——當你受到傷害後，你可以獲得造成此傷害的牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    界限突破<br>>
                    奸雄——當你受到傷害後，你可以獲得對你造成傷害的牌並摸一張牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    </p>
                   <p class="skill-title">技能解析：</p>
                    <p class="into-text">標準版<br>
                    奸雄——當你受到傷害後，你可以獲得造成此傷害的牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    界限突破<br>>
                    奸雄——當你受到傷害後，你可以獲得對你造成傷害的牌並摸一張牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    </p>
                     <p class="skill-title">FAQ</p>
                     <div class="faq">
                         <p class="into-text">
                             [Q]【奸雄】是否在滿足條件時必須發動？<br>
                             [A]【奸雄】可以選擇性發動。
                         </p>
                        <p class="into-text">[Q]當錦囊牌對曹操造成傷害時，曹操獲得哪張牌？
                            [A]只獲得相應的錦囊，例如曹操判定【閃電】受到傷害，可以將【閃電】收入手牌。<br>
                            [Q]角色A對角色B使用【借刀殺人】，要求B對曹操使用【殺】，若B使用了【殺】對曹操造成傷害，曹操獲得哪張牌？
                        </p>

                     </div> -->

          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import Header from '../../components/header/header.vue'
export default {
  name: 'ticket',
  props: {
    msg: String
  },
  components: {
    Header
  },
  data() {
    return {
      detail: {
        name: '',
        guide: '',
        stories: [
          {
            name: '',
            img: '',
            content: ''
          }
        ]
      },
      storie: {},
      id: this.$route.query.id || '',
      tab: 0,
      activeIndex: 0
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    changeCurrntTab(tab) {
      this.tab = tab
      if (tab == 0) {
        return
      }
      this.$nextTick(function () {
        this.initSwiper()
      })
    },
    initSwiper() {
      let _this = this
      new Swiper('.swiper-container', {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        initialSlide: 0,
        loop: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        observeParents: true,
        observer: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          slideChange: function () {
            _this.activeIndex = this.realIndex
          }
        }
      })
    },
    getDetail() {
      let _this = this
      this.$http.get(`/walkthrough/hero/detail/${this.id}`, {}, (res) => {
        if (res.code == 0) {
          this.detail = res.data
          if (res.data.stories) {
            if (res.data.stories.length <= 0) {
              _this.detail.stories = [
                {
                  name: '',
                  img: '',
                  content: ''
                }
              ]
            }
          }
        } else {
          this.$toast.center(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import '/heroDetail';
</style>

