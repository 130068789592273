<template>
  <div class="detail">
    <Header title="票選結果"></Header>
    <div class="content">
      <p class="problem">{{ detail.title }}</p>
      <p class="tiket-time">票選時間：{{ detail.btime | formatDate }}-{{ detail.etime | formatDate }}</p>
      <div class="strategy-detail">
        <div
          class="skill-into"
          v-html="detail.content"
        >
          <!-- <p class="heroName">魏武帝曹操</p>
                    <p class="skill-title">技能介紹：</p>
                    <p class="into-text">標準版<br>
                    奸雄——當你受到傷害後，你可以獲得造成此傷害的牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    界限突破<br>>
                    奸雄——當你受到傷害後，你可以獲得對你造成傷害的牌並摸一張牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    </p>
                   <p class="skill-title">技能解析：</p>
                    <p class="into-text">標準版<br>
                    奸雄——當你受到傷害後，你可以獲得造成此傷害的牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    界限突破<br>>
                    奸雄——當你受到傷害後，你可以獲得對你造成傷害的牌並摸一張牌。<br>
                    護駕——主公技，當你需要使用或打出【閃】時，你可以令其他魏勢力角色選擇是否打出一張【閃】（視為由你使用或打出）。<br>
                    </p>
                     <p class="skill-title">FAQ</p>
                     <div class="faq">
                         <p class="into-text">
                             [Q]【奸雄】是否在滿足條件時必須發動？<br>
                             [A]【奸雄】可以選擇性發動。
                         </p>
                        <p class="into-text">[Q]當錦囊牌對曹操造成傷害時，曹操獲得哪張牌？
                            [A]只獲得相應的錦囊，例如曹操判定【閃電】受到傷害，可以將【閃電】收入手牌。<br>
                            [Q]角色A對角色B使用【借刀殺人】，要求B對曹操使用【殺】，若B使用了【殺】對曹操造成傷害，曹操獲得哪張牌？
                        </p> -->

          <!-- </div> -->

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '../../components/header/header.vue'
import { formatDate } from '../../api/util.js'
export default {
  name: 'detail',
  data() {
    return {
      detail: {},
      id: this.$route.query.id || ''
    }
  },
  components: {
    Header
  },
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      console.log(new Date(time))
      return formatDate(date, 'yyyy年MM月dd日')
    }
  },
  created() {
    this.getResult()
  },
  methods: {
    getResult() {
      this.$http.get(`/interact/poll/result/${this.id}`, {}, (res) => {
        if (res.code == 0) {
          this.detail = res.data
        } else {
          this.$toast.center(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '/detail';
</style>

