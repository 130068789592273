<template>
    <div class="strategy">
       <tab-nav isActive="strategy"></tab-nav>
       <ul class="camp-list flex">
           <li :class="['flex align-center',tab==0?'active':'']" @click="changeTabCurrent(0)"><i class="icon wei"></i></li>
           <li :class="['flex align-center',tab==1?'active':'']" @click="changeTabCurrent(1)"><i class="icon shu"></i></li>
           <li :class="['flex align-center',tab==2?'active':'']" @click="changeTabCurrent(2)"><i class="icon wu"></i></li>
           <li :class="['flex align-center',tab==3?'active':'']" @click="changeTabCurrent(3)"><i class="icon qun"></i></li>
       </ul>
       <div class="hero-box">
           <ul class="hero-list flex">
               <li v-for="(item,index) in heroData" :key="index">
                   <router-link class="go-btn" :to="{name:'heroDetail',query:{id:item.id}}">
                       <div class="streagy-img"> <img :src="item.img" alt=""></div> 
                        <p class="hero-name">{{item.name}}</p>
                   </router-link>
                  
               </li>
           </ul>
       </div>
    </div>
</template>
<script>
import tabNav from '../../components/tabNav/tabNav.vue'
export default {
  name: 'ticket',
  components: {
        tabNav,
  },
  props: {
    msg: String
  },
  data(){
		return{
			detail:{},
            id:this.$route.query.id||'',
            heroList:[{country: "魏", heros: []},{country: "蜀", heros: []},{country: "吳", heros: []},{country: "群", heros: []}],
            heroData:[],
            tab:0,
		}
  },
 filters: {
     formatDate(time) {
        time = time * 1000
        let date = new Date(time)
        console.log(new Date(time))
        return formatDate(date, 'yyyy年MM月dd日');
      }
  },
created(){
        this.getHeroList();
},
methods:{
        changeTabCurrent(tab){
            this.tab=tab;
            if(this.heroList[tab]){
                 this.heroData=this.heroList[tab].heros;
            }else{
                 this.heroData=[]
            }
        },
        getHeroList(){
        this.$http.get(`/walkthrough/hero/list`,{} , res =>{
                if(res.code==0){
                    this.heroList=res.data.list;
                    if(res.data.list[this.tab]){
                      this.heroData=res.data.list[this.tab].heros;
                    }
                }else{
                this.$toast.center(res.msg);  
                }
        })
        },
},
}
</script>
<style lang="scss">
@import "/strategy";
</style>