<template>
  <div class="header">
    <i class="back-btn" @click="changeGoBackRouter()"></i>
    {{title}}
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
  title: {
      type: String,
      default: "三国茶馆",
    },
  },
  methods: {
    changeGoBackRouter(){
         window.history.go(-1)
    },
  }

}
</script>

<style>
.header{
    width: 100%;
    height: 1.4133rem;
    line-height:1.4133rem;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    font-size: 0.45rem;
	font-weight: bold;
	color: #000000;
}
.back-btn{
    width: 0.47rem;
    height: 0.47rem;
    background: url(../../assets/back-btn.png) center center no-repeat;
    background-size:.2rem;
    position: absolute;
    left:.2667rem;
    top: 50%;
    transform: translateY(-50%);
}

</style>
