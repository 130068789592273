<template>
  <div class="ticket">
   <tab-nav isActive="ticket"></tab-nav>
   <div class="plan1">
       <p class="problem">{{pollData.votes[0].name}}</p>
       <p class="tiket-time">票選時間：{{pollData.votes[0].btime|formatDate}}-{{pollData.votes[0].etime|formatDate}}</p>
       <ul class="hero-list flex">
           <li v-for="(item,index) in pollData.votes[0].options" :key="index" >
               <div class="hero-box" @click="changeOpenDetail(item.detail)">
                   <div class="img-box">
                      <img  :src="item.img" alt="">
                   </div>
                    
                    <div class="describe">
                        <p class="skill">技能</p>
                        <div class="tag flex" v-html="item.intro"></div>
                    </div>
                    <p class="ticket-name">{{item.name}}</p>
               </div>
               <!-- 0-用户未投票，1-用户已投票 -->
               <button :class="['icon ticket-btn',item.status==0?'':'ticketed']" :disabled="item.status==1" @click="changeVote(item.id,pollData.votes[0].id,index)"></button>
               <p class="ticket-num">已獲得：{{item.num}}票</p>
              
            </li>
       </ul>
       <p class="tips-text">* {{pollData.votes[0].remark}}！</p>
   </div>
   <div class="plan2">
      <p class="problem">{{pollData.feedbacks[0].name}}</p>
      <p class="tiket-time">填寫時間：{{pollData.feedbacks[0].btime|formatDate}}-{{pollData.feedbacks[0].etime|formatDate}}</p>
      <div class="form">
          <textarea v-model="heroName" :placeholder="pollData.feedbacks[0].placeholder"></textarea>
          <!-- <input type="text" v-model="heroName" :placeholder="pollData.feedbacks[0].placeholder"> -->
          <div class="tips"><i class="icon tips-icon"></i>{{pollData.feedbacks[0].tip}}</div>
          <button :class="['icon sure-btn',pollData.feedbacks[0].status==0?'':'submitted']" :disabled="pollData.feedbacks[0].status==1" @click="saveFeedback(pollData.feedbacks[0].id)"></button>
      </div>
      <p class="tips-text">* {{pollData.feedbacks[0].remark}}</p>
   </div> 
   <div class="plan3">
       <ul class="news-list">
           <li class="flex" v-for="(item,index) in pollData.vote_results" :key="index">
               <div class="news-img">
                 <img :src="item.img" alt="">
               </div> 
               <div class="news-content">
                   <p class="news-title">{{item.title}}</p>
                   <p class="news-date">{{item.etime|formatDate}}</p>
                   <p class="look-btn flex align-center"><router-link class="flex align-center" :to="{name:'detail',query:{id:item.id}}">查看詳情 <i class="icon arrow-icon"></i></router-link></p>
               </div>
           </li>
       </ul>

   </div>
    <div class="fade" v-if="isLoginFade||isOpenHeroFade" @click="changeCloseModel"></div>
    <login :isLoginFade="isLoginFade"  v-on:changeCloseFade="changeCloseModel"></login>
    <div class="hero-alert" v-if="isOpenHeroFade">
          <a href="javascript:;" class="icon close-btn" @click="changeCloseModel"></a>
          <div class="hero-content" v-html="detail"></div> 
    </div> 
  </div>
</template>

<script>
import tabNav from '../../components/tabNav/tabNav.vue'
import {formatDate} from '../../api/util.js'
import login from '../../components/login/login.vue'
export default {
  name: 'ticket',
  components: {
        tabNav,
        login
  },
  props: {
    msg: String
  },
  data(){
		return{
			token:localStorage.getItem('token')||'',
            pollData:{
                votes:[{}],
                vote_results:[{}],
                feedbacks:[{}],
                },
            heroName:'',  
            isLoginFade:false,
            isOpenHeroFade:false,
            detail:'',
            
		}
  },
  filters: {
     formatDate(time) {
       if(time){
         time = time * 1000
       }
     if(!time){
         time = 1 * 1000
       }
        let date = new Date(time)
        console.log('ddd',time)
        return formatDate(date, 'yyyy年MM月dd日')
      }
  },
  created: function () {
      this.getPollList();
  },
  methods:{
    changeOpenDetail(detail){
        this.detail=detail;
        this.isOpenHeroFade=true;
    },
    changeCloseModel(){
        this.isLoginFade=false;
        this.isOpenHeroFade=false;
    },
   changeVote(option_id,vote_id,index){
        if(this.token==''){
            this.isLoginFade=true;
             return;
        }
        this.$http.post(`/interact/poll/vote/${vote_id}`,{option_id:option_id} , res =>{
            if(res.code==0){
              this.$toast.center('投票成功~');  
              this.pollData.votes[0].options[index].status=1;
              this.pollData.votes[0].options[index].num=this.pollData.votes[0].options[index].num+1
            }else{
              if(res.code==401){
                      localStorage.clear();            
                                        
                 }else{
                    this.$toast.center(res.msg);  
              }  
            }
      }) 
   },
    saveFeedback(id){
        if(this.token==''){
            this.isLoginFade=true;
             return;
        }
        if(this.heroName==''){
            this.$toast.center(this.pollData.feedbacks[0].placeholder);  
            return;
        }
        this.$http.post(`/interact/poll/feedback/${id}`,{content:this.heroName} , res =>{
            if(res.code==0){
              this.$toast.center('提交成功~'); 
              this.pollData.feedbacks[0].status=1;
            }else{
                 if(res.code==401){
                      localStorage.clear();            
                                        
                 }else{
                    this.$toast.center(res.msg);  
                  }
            }
      }) 
   },
    getPollList(){
       this.$loading();
       this.$http.get('/interact/poll/list',{} , res =>{
            if(res.code==0){
              this.pollData=res.data;
              this.$loading.close();
            }else{
              this.$toast.center(res.msg);  
                                   
            }
      })
    },
	},

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
@import "/ticket";
</style>
